import { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./FrameComponent.module.css";

const FrameComponent = ({
  className = "",
  frameDivPadding = false,
  maskGroup,
  vector2,
  aboutUsHeight,
  aboutUsDisplay,
  aboutUsAlignItems,
  aboutUsJustifyContent,
  supportingYouToContainerFlex,
  supportingYouToContainerMargin,
}) => {
  const aboutUsStyle = useMemo(() => {
    return {
      height: aboutUsHeight,
      display: aboutUsDisplay,
      alignItems: aboutUsAlignItems,
      justifyContent: aboutUsJustifyContent,
    };
  }, [aboutUsHeight, aboutUsDisplay, aboutUsAlignItems, aboutUsJustifyContent]);

  const supportingYouToContainerStyle = useMemo(() => {
    return {
      flex: supportingYouToContainerFlex,
      margin: supportingYouToContainerMargin,
    };
  }, [supportingYouToContainerFlex, supportingYouToContainerMargin]);
  
  return (
    <div className={[styles.maskGroupParent, className,frameDivPadding?styles.frameDivePadding:''].join(" ")}>
      <img
        className={styles.maskGroupIcon}
        loading="lazy"
        alt=""
        src={maskGroup}
      />
      <img
        className={styles.componentChild}
        loading="lazy"
        alt=""
        src={vector2}
      />
      <div className={styles.aboutSupport}>
        <div className={styles.aboutUs} style={aboutUsStyle}>
          About Us
        </div>
        <h1
          className={styles.supportingYouToContainer}
          style={supportingYouToContainerStyle}
        >
          <span className={styles.supportingYouTo}>Supporting you to</span>
          {` `}
          <span className={styles.overcomeLegal}>{`overcome legal `}</span>
          <span className={styles.issuesAndEase}>issues and ease</span> the
          burden
        </h1>
      </div>
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
  maskGroup: PropTypes.string,
  vector2: PropTypes.string,

  /** Style props */
  frameDivPadding: PropTypes.bool,
  aboutUsHeight: PropTypes.string,
  aboutUsDisplay: PropTypes.string,
  aboutUsAlignItems: PropTypes.string,
  aboutUsJustifyContent: PropTypes.string,
  supportingYouToContainerFlex: PropTypes.string,
  supportingYouToContainerMargin: PropTypes.string,
};

export default FrameComponent;
