import Case from "./Case";
import PropTypes from "prop-types";
import styles from "./LastCases.module.css";

const LastCases = ({ className = "" }) => {
  return (
    <section className={[styles.lastcases, className].join(" ")}>
      <div className={styles.casesHeader}>
        <h1 className={styles.weHaveHandled}>
          We have handled cases from some of our clients
        </h1>
        <div className={styles.seeMore}>
          <div className={styles.seeMore1}>See more</div>
          <img
            className={styles.arrowRight3}
            loading="lazy"
            alt=""
            src={process.env.PUBLIC_URL+"/arrow--right-3-16.svg"}
          />
        </div>
      </div>
      <div className={styles.caseList}>
        <Case maskGroup={process.env.PUBLIC_URL+"/mask-group-6@2x.png"} />
        <Case maskGroup={process.env.PUBLIC_URL+"/mask-group-6@2x.png"} />
      </div>
    </section>
  );
};

LastCases.propTypes = {
  className: PropTypes.string,
};

export default LastCases;
