import PropTypes from "prop-types";
import styles from "./EducationDetiale.module.css";

const EducationDetiale = ({
  className = "",
  degree = "Bachelor of Law",
  univierstyName = "American University in the Emirates",
}) => {
  return (
    <div className={[styles.educationdetiale, className].join(" ")}>
      <h2 className={styles.bachelorOfLaw}>{degree}</h2>
      <h3 className={styles.americanUniversityIn}>{univierstyName}</h3>
    </div>
  );
};

EducationDetiale.propTypes = {
  className: PropTypes.string,
  degree: PropTypes.string,
  univierstyName: PropTypes.string,
};

export default EducationDetiale;
