import { Button } from "@mui/material";
import PropTypes from "prop-types";
import styles from "./Case.module.css";

const Case = ({ className = "", maskGroup }) => {
  return (
    <div className={[styles.case, className].join(" ")}>
      <img
        className={styles.maskGroupIcon}
        loading="lazy"
        alt=""
        src={maskGroup}
      />
      <div className={styles.caseContent}>
        <h1 className={styles.theCaseOf}>
          The Case of XXXXXXXXXXX Corruption of Money at XXXXX Bank
        </h1>
        <div
          className={styles.loremIpsumDolor}
        >{`Lorem ipsum dolor sit amet consectetur. Commodo pulvinar molestie pellentesque urna libero velit porta. Velit pellentesque hac gravida pellentesque est semper. `}</div>
        <Button
          className={styles.caseDetails}
          disableElevation
          variant="contained"
          sx={{
            textTransform: "none",
            color: "#fff",
            fontSize: "24",
            background: "#002340",
            borderRadius: "0px 0px 0px 0px",
            "&:hover": { background: "#002340" },
            width: 250,
          }}
        >
          See detail
        </Button>
      </div>
    </div>
  );
};

Case.propTypes = {
  className: PropTypes.string,
  maskGroup: PropTypes.string,
};

export default Case;
