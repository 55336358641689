import { useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./TeamCard.module.css";

const TeamCard = ({
  className = "",
  name1 = "Mariam Al Shamsi",
  postion = "partner",
  experience = "",
  education = [],
  teamCardHeight,
  maskGroup,
  maskGroupIconAlignSelf,
  maskGroupIconOverflow,
  maskGroupIconWidth,
  maskGroupIconFlex,
  maskGroupIconMaxHeight,
  maskGroupIconHeight,
  teamCardInfoWidth,
  teamCardInfoAlignSelf,
  mariamAlShamsiAlignSelf,
  mariamAlShamsiWidth,
  mariamAlShamsiDisplay,
  partnerTextDecoration,
  partnerWidth,
  partnerDisplay,
}) => {
  const teamCardStyle = useMemo(() => {
    return {
      height: teamCardHeight,
    };
  }, [teamCardHeight]);

  const maskGroupIconStyle = useMemo(() => {
    return {
      alignSelf: maskGroupIconAlignSelf,
      overflow: maskGroupIconOverflow,
      width: maskGroupIconWidth,
      flex: maskGroupIconFlex,
      maxHeight: maskGroupIconMaxHeight,
      height: maskGroupIconHeight,
    };
  }, [
    maskGroupIconAlignSelf,
    maskGroupIconOverflow,
    maskGroupIconWidth,
    maskGroupIconFlex,
    maskGroupIconMaxHeight,
    maskGroupIconHeight,
  ]);

  const teamCardInfoStyle = useMemo(() => {
    return {
      width: teamCardInfoWidth,
      alignSelf: teamCardInfoAlignSelf,
    };
  }, [teamCardInfoWidth, teamCardInfoAlignSelf]);

  const mariamAlShamsiStyle = useMemo(() => {
    return {
      alignSelf: mariamAlShamsiAlignSelf,
      width: mariamAlShamsiWidth,
      display: mariamAlShamsiDisplay,
    };
  }, [mariamAlShamsiAlignSelf, mariamAlShamsiWidth, mariamAlShamsiDisplay]);

  const partnerStyle = useMemo(() => {
    return {
      textDecoration: partnerTextDecoration,
      width: partnerWidth,
      display: partnerDisplay,
    };
  }, [partnerTextDecoration, partnerWidth, partnerDisplay]);

  const navigate = useNavigate();

  const onTeamCardContainerClick = useCallback(() => {
    navigate("/lawyer-detail",{state:{name:name1,experience:experience,education:education,position:postion,image:maskGroup}});
  }, [navigate]);

  return (
    <div
      className={[styles.teamcard, className].join(" ")}
      onClick={onTeamCardContainerClick}
      style={teamCardStyle}
    >
      <img
        className={styles.maskGroupIcon}
        loading="lazy"
        alt=""
        src={maskGroup}
        style={maskGroupIconStyle}
      />
      <div className={styles.teamCardInfo} style={teamCardInfoStyle}>
        <h1 className={styles.mariamAlShamsi} style={mariamAlShamsiStyle}>
          {name1}
        </h1>
        <div className={styles.partner} style={partnerStyle}>
          {postion}
        </div>
      </div>
    </div>
  );
};

TeamCard.propTypes = {
  className: PropTypes.string,
  name1: PropTypes.string,
  postion: PropTypes.string,
  maskGroup: PropTypes.string,

  /** Style props */
  teamCardHeight: PropTypes.string,
  maskGroupIconAlignSelf: PropTypes.string,
  maskGroupIconOverflow: PropTypes.string,
  maskGroupIconWidth: PropTypes.string,
  maskGroupIconFlex: PropTypes.string,
  maskGroupIconMaxHeight: PropTypes.string,
  maskGroupIconHeight: PropTypes.string,
  teamCardInfoWidth: PropTypes.string,
  teamCardInfoAlignSelf: PropTypes.string,
  mariamAlShamsiAlignSelf: PropTypes.string,
  mariamAlShamsiWidth: PropTypes.string,
  mariamAlShamsiDisplay: PropTypes.string,
  partnerTextDecoration: PropTypes.string,
  partnerWidth: PropTypes.string,
  partnerDisplay: PropTypes.string,
};

export default TeamCard;
