import PropTypes from "prop-types";
import styles from "./NavigationButton.module.css";
import { Button } from "@mui/material";

const NavigationButton = ({
  className = "",
  color = "Default",
  state = "Default",
  name1 = "About",
  onNavigationButtonContainerClick,

}) => {
  return (
    <div
      className={[styles.root, className].join(" ")}
      data-color={color}
      data-state={state}
    >
      <Button variant="text" className={styles.text} onClick={()=>{onNavigationButtonContainerClick(name1)}}>{name1}</Button>
    </div>
  );
};

NavigationButton.propTypes = {
  className: PropTypes.string,
  name1: PropTypes.string,

  /** Variant props */
  color: PropTypes.string,
  state: PropTypes.string,

  /** Action props */
  onNavigationButtonContainerClick: PropTypes.func,

};

export default NavigationButton;
