import PageHaeder from "../components/PageHaeder";
import Footer from "../components/Footer";
import styles from "./WhyChoose.module.css";

const WhyChoose = () => {
  return (
    <div className={styles.whyChoose}>
      <PageHaeder pageName="Why Choose TRIUMPH & CO." pageDetails="Choose TRIUMPH & CO. for a partnership that combines international experience, local insight, and a steadfast commitment to your success. We are your trusted legal advisors, dedicated to helping you navigate the complexities of the legal world with confidence."/>
      <main className={styles.frameParent}>
        <section className={styles.atTriumphCoWeUnderstanParent}>
          <div className={styles.atTriumphContainer}>
            <p className={styles.atTriumphCoWeUnderstan}>
              <span>
                <span
                  className={styles.atTriumph}
                >{`At TRIUMPH & CO., we understand that choosing the right legal partner is crucial for `}</span>
              </span>
            </p>
            <p className={styles.atTriumphCoWeUnderstan}>
              <span>
                <span className={styles.atTriumph}>
                  your success. Here’s why we stand out:
                </span>
              </span>
            </p>
            <p className={styles.atTriumphCoWeUnderstan}>
              <span>
                <span className={styles.blankLine1}>&nbsp;</span>
              </span>
            </p>
            <p className={styles.globalReachAnd}>
              1.Global Reach and Local Expertise
            </p>
            <p
              className={styles.withOfficesIn}
            >{`With offices in Lebanon and the UAE, and ongoing expansion plans into London-UK and `}</p>
            <p
              className={styles.withOfficesIn}
            >{`Saudi Arabia, we offer a unique blend of global presence and local knowledge. Our team `}</p>
            <p
              className={styles.withOfficesIn}
            >{`of internationally qualified lawyers and solicitors are well-versed in various jurisdictions, `}</p>
            <p className={styles.withOfficesIn}>
              ensuring you receive comprehensive legal support.
            </p>
            <p
              className={styles.withOfficesIn}
            >{`In addition, and due to our extensive network of partner firms, we are able to cover global `}</p>
            <p className={styles.withOfficesIn}>
              jurisdictions and assist our clients anywhere across the globe.
            </p>
          </div>
          <img
            className={styles.image128Icon}
            loading="lazy"
            alt=""
            src={process.env.PUBLIC_URL+"/image-128@2x.png"}
          />
        </section>
        <section className={styles.oneStopShopApproachContainer}>
          <p className={styles.withOfficesIn}>&nbsp;</p>
          <p className={styles.globalReachAnd}>2. One-stop shop approach</p>
          <p className={styles.atTriumphCoWeUnderstan}>
            <span className={styles.blankLine1}>
              <span>{`Through our group partners, we offer our clients a wide range of services such as accounting 
                      and auditing services, HR services, CSP support, trusts and foundations setup, marketing and 
                      branding, therefore offering our clients a one-stop shop approach for all their required 
                      services for a focused and customized experience supporting clients with the running of their 
                      business and operations. By relying on us, our clients are offered a streamlined service allowing 
                      them to expand and grow without the challenges of endeavoring to find various service 
                      providers for their different needs. `}</span>
            </span>
          </p>
          <p className={styles.innovativeLegalAndFiscalSo}>
            <span className={styles.blankLine1}>
              <span>Innovative Legal and Fiscal Solutions</span>
            </span>
          </p>
          <p className={styles.atTriumphCoWeUnderstan}>
            <span className={styles.blankLine1}>
              <span>{`We pride ourselves on delivering innovative solutions tailored to meet the specific needs of our clients. `}</span>
            </span>
          </p>
          <p className={styles.atTriumphCoWeUnderstan}>
            <span className={styles.blankLine1}>
              <span>{`Whether you are dealing with complex corporate matters, fiscal challenges, construction 
                      disputes, or other, or need expert advice on regulatory compliance, our forward-thinking approach ensures optimal outcomes.`}
              </span>
            </span>
          </p>
          <p className={styles.atTriumphCoWeUnderstan}>
            <span className={styles.blankLine1}>
              <span>approach ensures optimal outcomes.</span>
            </span>
          </p>
          <p className={styles.atTriumphCoWeUnderstan}>
            <span className={styles.blankLine1}>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className={styles.globalReachAnd}>3.Commitment to Excellence</p>
          <p className={styles.withOfficesIn}>
            {`Our firm is registered with the Solicitors Regulation Authority, underscoring our commitment 
            to maintaining the highest standards of professionalism and ethical practice. We strive for 
            excellence in every matter we handle, providing you with legal services that are both reliable 
            and effective.`}
          </p>
        </section>
        <img
            style={{maxWidth: "98%"}}
            className={styles.frameChild}
            loading="lazy"
            alt=""
            src={process.env.PUBLIC_URL+"/frame-294@2x.png"}
          />       
          <section className={styles.clientCentricApproachAtTriParent}>
          <div className={styles.clientCentricApproachAtContainer}>
            <p className={styles.globalReachAnd}>4.Client-Centric Approach</p>
            <p
              className={styles.withOfficesIn}
            >{`At TRIUMPH & CO., our clients are at the `}</p>
            <p
              className={styles.withOfficesIn}
            >{`heart of everything we do. We take the time to understand your unique needs and goals, offering personalized legal strategies that drive success. `}</p>
            <p className={styles.withOfficesIn}>
              {`Our dedication to building strong, long-term relationships means you can count on us for `}
            </p>
            <p className={styles.withOfficesIn}>
              unwavering support and guidance.
            </p>
          </div>
          <div className={styles.experiencedAndDiverseContainer}>
            <p className={styles.globalReachAnd}>
              5. Experienced and Diverse Team
            </p>
            <p
              className={styles.withOfficesIn}
            >{`Our team comprises lawyers qualified in various jurisdictions, including England and Wales, `}</p>
            <p
              className={styles.withOfficesIn}
            >{`Lebanon, and the UAE. This diverse expertise allows us to navigate different legal systems `}</p>
            <p className={styles.withOfficesIn}>
              seamlessly and provide you with well-rounded advice and
              representation.
            </p>
          </div>
        </section>
        <section className={styles.strategicExpansionAsContainer}>
          <p className={styles.globalReachAnd}>6. Strategic Expansion</p>
          <p className={styles.withOfficesIn}>
            {`As we expand into new markets, we bring our proven expertise and innovative solutions to `}</p>
          <p className={styles.withOfficesIn}>
            {`a broader audience. Our strategic growth into London and Saudi Arabia reflects our ambition `}
          </p>
          <p
            className={styles.withOfficesIn}
          >{`to serve clients across the Middle East and globally, ensuring that TRIUMPH & CO. remains at `}</p>
          <p className={styles.withOfficesIn}>
            the forefront of global legal excellence.
          </p>
          <p className={styles.withOfficesIn}>&nbsp;</p>
          <p className={styles.globalReachAnd}>7. Holistic Legal Services</p>
          <p
            className={styles.withOfficesIn}
          >{`From corporate law and fiscal advisory to regulatory compliance and dispute resolution, `}</p>
          <p
            className={styles.withOfficesIn}
          >{`we offer a comprehensive range of services designed to meet all your legal needs under one `}</p>
          <p
            className={styles.withOfficesIn}
          >{`roof. Our holistic approach saves you time and ensures consistency and quality in all the `}</p>
          <p className={styles.withOfficesIn}>services we provide.</p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default WhyChoose;
