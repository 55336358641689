import NewsCard from "./NewsCard";
import PropTypes from "prop-types";
import styles from "./News.module.css";

const News = ({ className = "" }) => {
  return (
    <section className={[styles.news, className].join(" ")}>
      <div className={styles.presenceItem}>
        <div className={styles.news1}>News</div>
        <h1 className={styles.theLatestNewsContainer}>
          <p className={styles.theLatestNews}>{`The latest news `}</p>
          <p
            className={styles.theLatestNews}
          >{`and blog from TRIUMPH & CO.`}</p>
        </h1>
      </div>
      <div className={styles.triumphInfo}>
        <NewsCard maskGroup={process.env.PUBLIC_URL+"/mask-group-1@2x.png"} />
        <NewsCard maskGroup={process.env.PUBLIC_URL+"/mask-group-1@2x.png"} />
        <NewsCard maskGroup={process.env.PUBLIC_URL+"/mask-group-1@2x.png"} />
      </div>
    </section>
  );
};

News.propTypes = {
  className: PropTypes.string,
};

export default News;
