import Button1 from "./Button1";
import PropTypes from "prop-types";
import styles from "./Hero.module.css";

const Hero = ({ className = "" }) => {
  return (
    <section className={[styles.hero, className].join(" ")}>
      <div className={styles.hero1}>
        <h1 className={styles.exceedingExpectationsElevatContainer}>
          <p className={styles.exceeding}>Exceeding</p>
          <p className={styles.exceeding}>{`Expectations, `}</p>
          <p className={styles.exceeding}>Elevating Your Success</p>
        </h1>
        <h1 className={styles.triumphCoContainer}>
          <p className={styles.triumph}>{`TRIUMPH `}</p>
          <p className={styles.triumph}>{`& CO. `}</p>
        </h1>
        <h1 className={styles.lawFirm}>{`Law Firm `}</h1>
        <Button1 state="Default" button1Button="Get Statrted" />

      </div>
      <img
        className={styles.maskGroupIcon}
        loading="lazy"
        alt=""
        src={process.env.PUBLIC_URL+"/mask-group@2x.png"}
      />
    </section>
  );
};

Hero.propTypes = {
  className: PropTypes.string,
};

export default Hero;
