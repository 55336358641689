import Button1 from "./Button1";
import PropTypes from "prop-types";
import styles from "./NewsCard.module.css";

const NewsCard = ({ className = "", maskGroup }) => {
  return (
    <div className={[styles.newscard, className].join(" ")}>
      <img
        className={styles.maskGroupIcon}
        loading="lazy"
        alt=""
        src={maskGroup}
      />
      <div className={styles.frameParent}>
        <div className={styles.casesHaveBeenHandledSuccesWrapper}>
          <h1
            className={styles.casesHaveBeen}
          >{`23 Cases Have Been Handled Successfully `}</h1>
        </div>
        <div className={styles.loremIpsumDolor}>
          Lorem ipsum dolor sit amet consecter Commodo pulvinar molestie
          pellentesque urna libero
        </div>
        <Button1 state="Default" button1Button="Read me" />

      </div>
    </div>
  );
};

NewsCard.propTypes = {
  className: PropTypes.string,
  maskGroup: PropTypes.string,
};

export default NewsCard;
