import PropTypes from "prop-types";
import styles from "./FirstNameField.module.css";

const FirstNameField = ({ className = "", firstName, enterFirstName }) => {
  return (
    <div className={[styles.firstNameField, className].join(" ")}>
      <h2 className={styles.firstName}>{firstName}</h2>
      <div className={styles.firstNameInput}>
        <div className={styles.enterFirstName}>{enterFirstName}</div>
      </div>
    </div>
  );
};

FirstNameField.propTypes = {
  className: PropTypes.string,
  firstName: PropTypes.string,
  enterFirstName: PropTypes.string,
};

export default FirstNameField;
