import { useCallback ,useState } from "react";
import { useNavigate } from "react-router-dom";
import {Drawer, IconButton } from "@mui/material";
import NavigationButton from "./NavigationButton";
import PropTypes from "prop-types";
import styles from "./NaveBar.module.css";
import ListIcon from '@mui/icons-material/List';

const NaveBar = ({ className = "", theme = "Default",pageName="Home" }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const onNavigationButtonContainerClick = useCallback((name) => {
    console.log(name)
    if (pageName != name || name == "Team" ){
      switch(name){
        case 'Home':
          navigate('/');
          break;
        case 'About':
          navigate('/about-us');
          break;
        case 'Service':
          navigate('/services');
          break;
        case 'Why Us':
          navigate('/why-choose')
          break;
        case 'Team':
          navigate('/lawyer')
          break;
        case 'Contact Us':
          navigate('/contact-us')
          break;
      }
    }
  }, [navigate]);


  return (
    <div style={{width:"100%", display:"flex", flexDirection:"row" , justifyContent:"flex-end"}}>
      <IconButton onClick={toggleDrawer(true)} className={styles.mobileShow}><ListIcon style={{color:theme=='Default'?"black":"white",margin:"10px" ,width:"25px",height:"25  px"}}/></IconButton>
      <Drawer open={open} anchor="right" onClose={toggleDrawer(false)} PaperProps={{
            sx: { width: "30%", background:pageName=='Home'?"#E8E9E1":"#002340"},
          }}>
       <nav className={[styles.root, className].join(" ")} data-theme={theme}>
          <NavigationButton
            color={theme}
            state={pageName=="Home" ? "clicked":"Default"}
            name1="Home"
            onNavigationButtonContainerClick={onNavigationButtonContainerClick}
          />
          <NavigationButton color={theme} state={pageName=="About"?"clicked":"Default"} name1="About"  onNavigationButtonContainerClick={onNavigationButtonContainerClick}/>
          <NavigationButton color={theme} state={pageName=="Service"?"clicked":"Default"} name1="Service" onNavigationButtonContainerClick={onNavigationButtonContainerClick}/>
          <NavigationButton color={theme} state={pageName=="Why Us"?"clicked":"Default"} name1="Why Us" onNavigationButtonContainerClick={onNavigationButtonContainerClick}/>
          <NavigationButton color={theme} state={pageName=="Team"?"clicked":"Default"} name1="Team" onNavigationButtonContainerClick={onNavigationButtonContainerClick}/>
          <NavigationButton color={theme} state={pageName=="Contact Us"?"clicked":"Default"} name1="Contact Us" onNavigationButtonContainerClick={onNavigationButtonContainerClick} />
        </nav>
      </Drawer>
      <nav className={[styles.root, className, styles.mobileHide].join(" ")} data-theme={theme}>
        <NavigationButton
          color={theme}
          state={pageName=="Home" ? "clicked":"Default"}
          name1="Home"
          onNavigationButtonContainerClick={onNavigationButtonContainerClick}
        />
        <NavigationButton color={theme} state={pageName=="About"?"clicked":"Default"} name1="About"  onNavigationButtonContainerClick={onNavigationButtonContainerClick}/>
        <NavigationButton color={theme} state={pageName=="Service"?"clicked":"Default"} name1="Service" onNavigationButtonContainerClick={onNavigationButtonContainerClick}/>
        <NavigationButton color={theme} state={pageName=="Why Us"?"clicked":"Default"} name1="Why Us" onNavigationButtonContainerClick={onNavigationButtonContainerClick}/>
        <NavigationButton color={theme} state={pageName=="Team"?"clicked":"Default"} name1="Team" onNavigationButtonContainerClick={onNavigationButtonContainerClick}/>
        <NavigationButton color={theme} state={pageName=="Contact Us"?"clicked":"Default"} name1="Contact Us" onNavigationButtonContainerClick={onNavigationButtonContainerClick} />
      </nav>
    </div>
  );
};

NaveBar.propTypes = {
  className: PropTypes.string,
  pageName: PropTypes.string,
  /** Variant props */
  theme: PropTypes.string,
};

export default NaveBar;
