import FrameComponent from "./FrameComponent";
import Button1 from "./Button1";
import PropTypes from "prop-types";
import styles from "./Support.module.css";

const Support = ({ className = "" }) => {
  return (
    <section className={[styles.support, className].join(" ")}>
      <FrameComponent
        maskGroup={process.env.PUBLIC_URL+"/mask-group-4@2x.png"}
        vector2={process.env.PUBLIC_URL+"/vector-2.svg"}
      />
      <h1
        className={styles.triumphCo}
      >{`Triumph & Co. is an international law firm with a global presence & local expertise offering innovative legal and fiscal services, with offices in Lebanon and the UAE, and currently expanding into London-UK, and Saudi Arabia. `}</h1>
      <Button1 state="Default" button1Button="See details" />

    </section>
  );
};

Support.propTypes = {
  className: PropTypes.string,
};

export default Support;
