import PropTypes from "prop-types";
import styles from "./Testimonials.module.css";

const Testimonials = ({ className = "" }) => {
  return (
    <section className={[styles.testimonials, className].join(" ")}>
      <div className={styles.testimonialHeader}>
        <h1 className={styles.testimonials1}>Testimonials</h1>
        <img
          className={styles.maskGroupIcon}
          loading="lazy"
          alt=""
          src={process.env.PUBLIC_URL+"/mask-group-8@2x.png"}
        />
      </div>
      <div className={styles.testimonial}>
        <img
          className={styles.imageIcon}
          loading="lazy"
          alt=""
          src={process.env.PUBLIC_URL+"/image@2x.png"}
        />
        <div className={styles.quoteContainerParent}>
          <div className={styles.quoteContainer}>
            <div className={styles.loremIpsumDolor}>
              Lorem ipsum dolor sit amet consectetur. Commodo pulvinar molestie
              pellentesque urna libero velit porta. Velit pellentesque hac
              gravida pellentesque est semper. Duis lectus gravida ultricies
              eleifend in pharetra faucibus orci sem. Proin ac a cursus
              praesent. Malesuada risus amet nunc posuere rhoncus accumsan
              congue id dolor. Convallis maecenas sed in pellentesque. Diam
              tristique semper mauris dolor amet. Dolor elit nunc et purus quam
              amet laoreet eu risus.
            </div>
            <img className={styles.icon} loading="lazy" alt="" src={process.env.PUBLIC_URL+"/dot.svg"}/>
          </div>
          <div className={styles.reviewerInfo}>
            <div className={styles.johnatanG}>{`Johnatan G - `}</div>
            <div className={styles.starRating}>
              <img className={styles.starIcon} alt="" src={process.env.PUBLIC_URL+"/star.svg"}/>
              <img className={styles.starIcon} alt="" src={process.env.PUBLIC_URL+"/star.svg"}/>
              <img className={styles.starIcon} alt="" src={process.env.PUBLIC_URL+"/star.svg"}/>
              <img className={styles.starIcon} alt="" src={process.env.PUBLIC_URL+"/star.svg"}/>
              <img className={styles.starIcon} alt="" src={process.env.PUBLIC_URL+"/star-4.svg"}/>
            </div>
            <div className={styles.reviewed}>
              <span>{`4.8/5 - `}</span>
              <span className={styles.reviewed1}>322 Reviewed</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Testimonials.propTypes = {
  className: PropTypes.string,
};

export default Testimonials;
