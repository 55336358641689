import EducationDetiale from "./EducationDetiale";
import PropTypes from "prop-types";
import styles from "./FrameComponent1.module.css";

const FrameComponent1 = ({ className = "", 
  educationDetails = [
    {degree:"Bachelor of Law",university:"American University in the Emirates"},
    {degree:"Master in Criminal Sciences",university:"American University in the Emirates"},
    {degree:"PHD Candidate",university:"American University in the Emirates"}],
  image = process.env.PUBLIC_URL+"/mask-group2@2x.png" }) => {
  return (
    <div className={[styles.maskGroupParent, className].join(" ")}>
      <img
        className={styles.maskGroupIcon}
        loading="lazy"
        alt=""
        src={image}
      />
      <h1 className={styles.education}>EDUCATION</h1>
      <div className={styles.educationdetiels}>
        {educationDetails.map(education =><EducationDetiale
          degree={education['degree']}
          univierstyName={education['university']}
        />)}
        
      </div>
    </div>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
  education: PropTypes.array
};

export default FrameComponent1;
