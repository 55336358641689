import PageHaeder from "../components/PageHaeder";
import FormContainer from "../components/FormContainer";
import Footer from "../components/Footer";
import styles from "./ContactUs.module.css";

const ContactUs = () => {
  return (
    <div className={styles.contactUs}>
      <PageHaeder pageName={"Contact Us"} pageDetails="TRIUMPH & CO. is an international law firm with a global presence & local expertise offering innovative legal and fiscal services with offices in Lebanon and the UAE"/>
      <section className={styles.formContainerWrapper}>
        <FormContainer />
      </section>
      <Footer />
    </div>
  );
};

export default ContactUs;
