import { useMemo } from "react";
import Logo from "./Logo";
import NaveBar from "./NaveBar";
import PropTypes from "prop-types";
import styles from "./Header.module.css";

const Header = ({
  className = "",
  headerHeight,
  headerBackgroundColor,
  headerPadding,
  logoBackground,
  logoPadding,
  logoBorderRadius,
  theme='Default',
  pageName
}) => {
  const headerStyle = useMemo(() => {
    return {
      height: headerHeight,
      backgroundColor: headerBackgroundColor,
      padding: headerPadding,
    };
  }, [headerHeight, headerBackgroundColor, headerPadding]);

  return (
    <header
      className={[styles.header, className].join(" ")}
      style={headerStyle}
    >
      <Logo tLogo1={process.env.PUBLIC_URL+"/t-logo-1@2x.png"} logoBackgroundColor={logoBackground} logoBorderRadius={logoBorderRadius} logoPadding={logoPadding}/>
      <div className={styles.header1}>
        <NaveBar theme={theme} pageName={pageName} />
      </div>
    </header>
  );
};

Header.propTypes = {
  className: PropTypes.string,

  /** Style props */
  headerHeight: PropTypes.string,
  headerBackgroundColor: PropTypes.string,
  headerPadding: PropTypes.string,
  logoBackground:PropTypes.string,
  logoPadding:PropTypes.string,
  logoBorderRadius:PropTypes.string,
  /** Nave props */
  pageName: PropTypes.string,
  /** Nave Variant */
  theme:PropTypes.string
  
};

export default Header;
