import PropTypes from "prop-types";
import styles from "./Image1.module.css";

const Image1 = ({ className = "" }) => {
  return (
    <div className={[styles.image21, className].join(" ")}>
      <img
        className={styles.imageIcon}
        loading="lazy"
        alt=""
        src={process.env.PUBLIC_URL+"/image1@2x.png"}
      />
    </div>
  );
};

Image1.propTypes = {
  className: PropTypes.string,
};

export default Image1;
