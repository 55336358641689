import PageHaeder from "../components/PageHaeder";
import TeamCard from "../components/TeamCard";
import Footer from "../components/Footer";
import styles from "./Lawyer.module.css";
import Attorneys from "../data/Attorney";
const Lawyer = () => {
  return (
    <div className={styles.lawyer}>
      <PageHaeder pageName={'Team'}/>
      <section className={styles.teamcardParent}>
        {Attorneys.map(attorney=>
          <TeamCard
            name1={attorney.name}
            postion={attorney.postion}
            maskGroup={attorney.maskGroup}
            experience={attorney.experience}
            education={attorney.education}
          />)
        }
      </section>
      <Footer />
    </div>
  );
};

export default Lawyer;

