import PageHaeder from "../components/PageHaeder";
import FrameComponent from "../components/FrameComponent";
import Footer from "../components/Footer";
import styles from "./AboutUs.module.css";

const AboutUs = () => {
  return (
    <div className={styles.aboutUs}>
      <PageHaeder pageName={'About us'} pageDetails="Triumph & Co. is an international law firm with a global presence & local expertise offering innovative legal and fiscal services, with offices in Lebanon and the UAE, and currently expanding into London-UK, and Saudi Arabia. "/>
      <FrameComponent
        frameDivPadding={true}
        maskGroup={process.env.PUBLIC_URL+"/mask-group-4@2x.png"}
        vector2={process.env.PUBLIC_URL+"/vector-2.svg"}
        aboutUsHeight="unset"
        aboutUsDisplay="unset"
        aboutUsAlignItems="unset"
        aboutUsJustifyContent="unset"
        supportingYouToContainerFlex="unset"
        supportingYouToContainerMargin="0"
      />
      <section className={styles.aboutTriumphCoTriumphParent}>
        <div className={styles.aboutTriumphContainer}>
          <p className={styles.aboutTriumph}>{`About Triumph & Co.:`}</p>
          <p className={styles.triumphCoIsAnInternatio}>
            <span className={styles.triumphCoIsAnInternatio1}>
              <span>{`Triumph & Co. is an international law firm with a global presence & local expertise offering innovative legal and fiscal services, with offices in Lebanon and the UAE, and currently expanding into London-UK, and Saudi Arabia. `}</span>
            </span>
          </p>
          <p className={styles.blankLine}>
            <span className={styles.triumphCoIsAnInternatio1}>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className={styles.aboutTriumph}>Our team:</p>
          <p className={styles.ourTeamConsists}>
            Our team consists of lawyers qualified in various jurisdictions
            including Lebanon and Solicitors qualified in England and Wales,
            with the firm being registered with the Solicitors Regulation
            Authority. The team also comprises senior claims consultants
            bolstering our construction disputes practice, as well as
            accountants advancing our tax practice.
          </p>
        </div>
        <div className={styles.missionStatementAtContainer}>
          <div>
            <p className={styles.aboutTriumph}>Mission Statement</p>
            <p
              className={styles.ourTeamConsists}
            >{`At Triumph & Co., our mission is to deliver innovative and high-quality legal and fiscal services with a deep commitment to professionalism, integrity, and client-focused solutions. By leveraging our international reach and local expertise, we strive to support our clients in navigating complex legal landscapes in the Middle East and beyond. Our diverse team of qualified lawyers and solicitors is dedicated to providing tailored, effective legal counsel that addresses the unique needs of each client, ensuring unparalleled legal services.`}</p>
          </div>
          <div>
            <p className={styles.aboutTriumph}>Vision Statement:</p>
            <p
              className={styles.ourTeamConsists}
            >{`Our vision at Triumph & Co.  is to be a leading international law firm renowned by our distinguished client-centric approach alongside excellent legal services. We aim to set the standard for excellence in legal and fiscal services across the Middle East and beyond, continuously expanding our reach and enhancing our capabilities. We envision a future where our firm is synonymous with innovation, trust, and exceptional client service, empowering businesses and individuals to achieve their goals.`}</p>
          </div>
         </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
