import { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./Logo.module.css";

const Logo = ({
  className = "",
  logoAlignSelf,
  logoJustifyContent,
  logoBorderRadius,
  logoBackgroundColor,
  logoPadding,
  tLogo1,
}) => {
  const logoStyle = useMemo(() => {
    return {
      alignSelf: logoAlignSelf,
      justifyContent: logoJustifyContent,
    };
  }, [
    logoAlignSelf,
    logoJustifyContent,
    logoBorderRadius,
  ]);
  
  const logoImageStyle = useMemo(()=>{
    return {
      backgroundColor: logoBackgroundColor,
      padding: logoPadding,
      borderRadius: logoBorderRadius,
    }
  },[logoBackgroundColor,
    logoPadding,
    logoBorderRadius
  ]);

  return (
    <div className={[styles.logo, className].join(" ")} style={logoStyle}>
      <img className={styles.tLogo1} loading="lazy" alt="" src={tLogo1} style={logoImageStyle} />
    </div>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  tLogo1: PropTypes.string,

  /** Style props */
  logoAlignSelf: PropTypes.string,
  logoJustifyContent: PropTypes.string,
  logoBorderRadius: PropTypes.string,
  logoBackgroundColor: PropTypes.string,
  logoPadding: PropTypes.string,
};

export default Logo;
