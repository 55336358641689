import PropTypes from "prop-types";
import styles from "./FooterContent.module.css";

const FooterContent = ({ className = "" }) => {
  return (
    <div className={[styles.footerContent, className].join(" ")}>
      <div className={styles.aboutUsColumn}>
        <div className={styles.aboutUs}>About Us</div>
        <div className={styles.aboutUsDescriptionContainer}>
          <div className={styles.triumphCoContainer}>
            <p
              className={styles.triumphCo}
            >{`Triumph & Co. is an international law firm `}</p>
            <p
              className={styles.triumphCo}
            >{`with a global presence & local expertise`}</p>
          </div>
        </div>
        <div className={styles.socialMediaIcons}>
          <img
            className={styles.akarIconsfacebookFill}
            loading="lazy"
            alt=""
            src={process.env.PUBLIC_URL+"/akariconsfacebookfill.svg"}
          />
          <img
            className={styles.akarIconsfacebookFill}
            loading="lazy"
            alt=""
            src={process.env.PUBLIC_URL+"/akariconslinkedinboxfill.svg"}
          />
          <img
            className={styles.akarIconsfacebookFill}
            loading="lazy"
            alt=""
            src={process.env.PUBLIC_URL+"/akariconstwitterfill.svg"}
          />
          <img
            className={styles.akarIconsfacebookFill}
            loading="lazy"
            alt=""
            src={process.env.PUBLIC_URL+"/antdesigninstagramfilled.svg"}
          />
        </div>
      </div>
      <div className={styles.aboutUsColumn}>
        <div className={styles.contactUsUnitedContainer}>
          <p className={styles.triumphCo}>Contact Us</p>
          <p className={styles.unitedArabEmirates}>United Arab Emirates</p>
        </div>
        <div className={styles.uAEContactDetails}>
          <div className={styles.uAEContactInfo}>
            <img
              className={styles.akarIconsfacebookFill}
              loading="lazy"
              alt=""
              src={process.env.PUBLIC_URL+"/call.svg"}
            />
            <div className={styles.phoneNumber}>
              <p className={styles.triumphCo}>+971 4 2554421</p>
              <p className={styles.triumphCo}>+971 50 7815828</p>
            </div>
          </div>
          <div className={styles.lebanonLocation}>
            <img
              className={styles.akarIconsfacebookFill}
              loading="lazy"
              alt=""
              src={process.env.PUBLIC_URL+"/location.svg"}
            />
            <div className={styles.office1909RegalContainer}>
              <p className={styles.triumphCo}>Office 1909, Regal Tower</p>
              <p className={styles.triumphCo}>Business Bay,Dubai, UAE</p>
            </div>
          </div>
          <div className={styles.uAEContactInfo}>
            <img className={styles.messageIcon} alt="" src={process.env.PUBLIC_URL+"/message.svg"}/>
            <div className={styles.educationLaw}>dubai@triumphco.com</div>
          </div>
        </div>
      </div>
      <div className={styles.aboutUsColumn}>
        <div className={styles.contactUsUnitedContainer}>
          <p className={styles.triumphCo}>Contact Us</p>
          <p className={styles.unitedArabEmirates}>BEIRUT-LEBANON</p>
        </div>
        <div className={styles.uAEContactDetails}>
          <div className={styles.uAEContactInfo}>
            <img
              className={styles.akarIconsfacebookFill}
              alt=""
              src={process.env.PUBLIC_URL+"/call-1.svg"}
            />
            <div className={styles.educationLaw}>+961 1 688888</div>
          </div>
          <div className={styles.lebanonLocation}>
            <img
              className={styles.akarIconsfacebookFill}
              alt=""
              src={process.env.PUBLIC_URL+"/location.svg"}
            />
            <div className={styles.office504BlockContainer}>
              <p className={styles.triumphCo}>Office 504, Block B, 44 Tower,</p>
              <p className={styles.triumphCo}>Saloumi Roundabout,</p>
              <p className={styles.triumphCo}>Beirut, Lebanon</p>
            </div>
          </div>
          <div className={styles.uAEContactInfo}>
            <img
              className={styles.akarIconsfacebookFill}
              alt=""
              src={process.env.PUBLIC_URL+"/message-1.svg"}
            />
            <div className={styles.educationLaw}>beirut@triumphco.com</div>
          </div>
        </div>
      </div>
    </div>
  );
};

FooterContent.propTypes = {
  className: PropTypes.string,
};

export default FooterContent;
