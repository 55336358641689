import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import styles from "./Attorney.module.css";

const Attorney = ({ className = "" }) => {
  return (
    <section className={[styles.attorney, className].join(" ")}>
      <div className={styles.attorneyContainer}>
        <img
          className={styles.image109Icon}
          loading="lazy"
          alt=""
          src={process.env.PUBLIC_URL+"/image-109@2x.png"}
        />
      </div>
      <div className={styles.attorneyInfo}>
        <header className={styles.searchContainer}>
          <h1 className={styles.lookingForA}>
            Looking for a specific Attorney?
          </h1>
          <TextField
            className={styles.searchInput}
            placeholder="Search by name"
            variant="outlined"
            sx={{
              "& fieldset": { border: "none" },
              "& .MuiInputBase-root": {
                height: "65px",
                backgroundColor: "#d9d9d9",
                borderRadius: "15px",
                fontSize: "32px",
              },
              "& .MuiInputBase-input": { color: "rgba(58, 58, 56, 0.4)" },
            }}
          />
        </header>
        <div className={styles.allAttorneys}>
          <div className={styles.allAttorneys1}>All Attorneys</div>
        </div>
      </div>
    </section>
  );
};

Attorney.propTypes = {
  className: PropTypes.string,
};

export default Attorney;
