import { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./Services.module.css";

const Services = ({
  className = "",
  serviceName = "Arbitration",
  servicesAlignSelf,
  servicesBorderBottom,
  arrowRight3,
}) => {
  const servicesStyle = useMemo(() => {
    return {
      alignSelf: servicesAlignSelf,
      borderBottom: servicesBorderBottom,
    };
  }, [servicesAlignSelf, servicesBorderBottom]);

  return (
    <div
      className={[styles.services, className].join(" ")}
      style={servicesStyle}
    >
      <img
        className={styles.arrowRight3}
        loading="lazy"
        alt=""
        src={arrowRight3}
      />
      <h1 className={styles.arbitration}>{serviceName}</h1>
    </div>
  );
};

Services.propTypes = {
  className: PropTypes.string,
  serviceName: PropTypes.string,
  arrowRight3: PropTypes.string,

  /** Style props */
  servicesAlignSelf: PropTypes.string,
  servicesBorderBottom: PropTypes.string,
};

export default Services;
