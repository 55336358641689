import PageHaeder from "../components/PageHaeder";
import Image1 from "../components/Image1";
import Footer from "../components/Footer";
import styles from "./Services2.module.css";

const Services2 = () => {
  return (
    <div className={styles.services}>
      <PageHaeder pageName="Service"/>
      <main className={styles.servicesInner}>
        <section className={styles.frameParent}>
          <div className={styles.corporateLawMergersAndAcqParent}>
            <div className={styles.corporateLawMergersContainer}>
            <p className={styles.commercialLaw}>
              1. Corporate Law
            </p>
              <p className={styles.mergersAndAcquisitionsAssi}>
                <span className={styles.mergersAndAcquisitionsAssi1}>
                  <span className={styles.mergersAndAcquisitions}>
                    <span className={styles.jointVenturesAnd}>
                      -Mergers and Acquisitions:
                    </span>
                  </span>
                  <span className={styles.assistingWithTheBuyingSel}>
                    <span
                      className={styles.mergersAndAcquisitions}
                    >{` Assisting with the buying, selling, and merging of companies, `}</span>
                  </span>
                  <span className={styles.assistingWithTheBuyingSel}>
                    ensuring compliance with all legal requirements.
                  </span>
                </span>
              </p>
              <p className={styles.mergersAndAcquisitionsAssi}>
                <span className={styles.mergersAndAcquisitionsAssi1}>
                  <span className={styles.corporateGovernance}>
                    <span className={styles.jointVenturesAnd}>
                      -Corporate Governance:
                    </span>
                  </span>
                  <span className={styles.assistingWithTheBuyingSel}>
                    <span
                      className={styles.corporateGovernance}
                    >{` Advising on the responsibilities of corporate directors and officers, `}</span>
                  </span>
                  <span className={styles.assistingWithTheBuyingSel}>
                    and ensuring compliance with regulatory requirements.
                  </span>
                </span>
              </p>
              <p className={styles.mergersAndAcquisitionsAssi}>
                <span className={styles.mergersAndAcquisitionsAssi1}>
                  <span className={styles.jointVenturesAnd}>
                    - Joint Ventures and Partnerships:
                  </span>
                  <span className={styles.assistingWithTheBuyingSel}>
                    {" "}
                    Structuring and negotiating joint ventures and partnerships
                    to align with strategic business goals.
                  </span>
                </span>
              </p>
              <p className={styles.mergersAndAcquisitionsAssi}>
                <span className={styles.mergersAndAcquisitionsAssi1}>
                  <span className={styles.jointVenturesAnd}>
                    - Company Formation and Structuring:
                  </span>
                  <span className={styles.assistingWithTheBuyingSel}>
                    {" "}
                    Helping clients establish new businesses and advising on the
                    optimal corporate structure.
                  </span>
                </span>
              </p>
            </div>
            <Image1 />
          </div>
          <div className={styles.fiscalAndTaxContainer}>
            <p className={styles.fiscalAndTax}> 2. Fiscal and Tax Advisory</p>
            <p className={styles.taxPlanningAndComplianceP}>
              <span className={styles.mergersAndAcquisitionsAssi1}>
                <span className={styles.taxPlanningAndComplianceP2}>
                  <span className={styles.jointVenturesAnd}>
                    Tax Planning and Compliance:
                  </span>
                  <span>
                    {" "}
                    Providing strategic tax planning and ensuring compliance
                    with local and international tax regulations.
                  </span>
                </span>
              </span>
            </p>
            <p className={styles.mergersAndAcquisitionsAssi}>
              <span className={styles.mergersAndAcquisitionsAssi1}>
                <span className={styles.jointVenturesAnd}>
                  Transfer Pricing:
                </span>
                <span className={styles.assistingWithTheBuyingSel}>
                  {" "}
                  Advising on transfer pricing policies and compliance with
                  international guidelines.
                </span>
              </span>
            </p>
            <p className={styles.mergersAndAcquisitionsAssi}>
              <span className={styles.mergersAndAcquisitionsAssi1}>
                <span className={styles.jointVenturesAnd}>
                  Tax Dispute Resolution:
                </span>
                <span className={styles.assistingWithTheBuyingSel}>
                  {" "}
                  Representing clients in tax disputes and negotiations with tax
                  authorities.
                </span>
              </span>
            </p>
          </div>
          <img
            className={styles.frameChild}
            loading="lazy"
            alt=""
            src={process.env.PUBLIC_URL+"/frame-294@2x.png"}
          />
          <div className={styles.frameGroup}>
            <div className={styles.commercialLawContractDrafParent}>
              <div className={styles.commercialLawContainer}>
                <p className={styles.commercialLaw}>3. Commercial Law</p>
                <p className={styles.mergersAndAcquisitionsAssi}>
                  <span className={styles.mergersAndAcquisitionsAssi1}>
                    <span>{`- Contract Drafting and Negotiation: Preparing, reviewing, and negotiating commercial `}</span>
                  </span>
                </p>
                <p className={styles.mergersAndAcquisitionsAssi}>
                  <span className={styles.mergersAndAcquisitionsAssi1}>
                    <span>contracts to protect client interests.</span>
                  </span>
                </p>
                <p className={styles.mergersAndAcquisitionsAssi}>
                  <span className={styles.mergersAndAcquisitionsAssi1}>
                    <span>{`- Distribution and Agency Agreements: Structuring agreements for distribution, agency, `}</span>
                  </span>
                </p>
                <p className={styles.mergersAndAcquisitionsAssi}>
                  <span className={styles.mergersAndAcquisitionsAssi1}>
                    <span>and franchising arrangements.</span>
                  </span>
                </p>
                <p className={styles.blankLine}>
                  <span className={styles.mergersAndAcquisitionsAssi1}>
                    <span>&nbsp;</span>
                  </span>
                </p>
              </div>
              <div  className={styles.commercialLawContainer}>
                <p className={styles.commercialLaw}>
                      4. Regulatory Compliance
                </p>
                <p
                  className={styles.complianceAuditsConducting}
                >{`- Compliance Audits: Conducting thorough compliance audits to ensure adherence to local `}</p>
                <p className={styles.complianceAuditsConducting}>
                  and international regulations.
                </p>
                <p
                  className={styles.complianceAuditsConducting}
                >{`- Anti-Money Laundering (AML) and Anti-Bribery Compliance: Advising on AML and `}</p>
                <p className={styles.complianceAuditsConducting}>
                  anti-bribery policies and procedures.
                </p>
                <p
                  className={styles.complianceAuditsConducting}
                >{`- Data Protection and Privacy: Ensuring compliance with data protection laws, `}</p>
                <p className={styles.complianceAuditsConducting}>
                  including GDPR and other relevant regulations.
                </p>
              </div>
              <div className={styles.arbitrationRepresentingContainer}>
                <p className={styles.commercialLaw}>5. Arbitration</p>
                <p className={styles.complianceAuditsConducting}>
                  - Representing clients in a broad range of commercial and
                  construction arbitrations,
                </p>
                <p
                  className={styles.complianceAuditsConducting}
                >{` before various institutions in the middle east and globally including DIAC, ADCCAC, `}</p>
                <p className={styles.complianceAuditsConducting}>
                  ArbitrateAD, DIFC-LCIA, LCIA, ICC
                </p>
              </div>
            </div>
            <img
              className={styles.maskGroupIcon}
              loading="lazy"
              alt=""
              src={process.env.PUBLIC_URL+"/mask-group3@2x.png"}
            />
          </div>
          <div className={styles.intellectualPropertyPleaseContainer}>
            <p className={styles.mergersAndAcquisitionsAssi}>
              <span>
                <span className={styles.familyAndPersonal}>
                  6. Intellectual Property:
                </span>
              </span>
            </p>
            <p className={styles.complianceAuditsConducting}>
              (please develop)
            </p>
            <p className={styles.complianceAuditsConducting}>&nbsp;</p>
            <p className={styles.commercialLaw}>7. Litigation</p>
            <p className={styles.complianceAuditsConducting}>
              - Commercial Litigation: Representing clients in commercial
              disputes, including breach of contract and business torts.
            </p>
            <p className={styles.complianceAuditsConducting}>
              - Employment Disputes: Handling disputes related to employment law,
              including wrongful termination and workplace discrimination.
            </p>
            <p className={styles.complianceAuditsConducting}>
              - Construction: handling complex construction disputes around
              certified works, extension of time, prolongation costs,
              variations, delay damages, among others.
            </p>
            <p className={styles.complianceAuditsConducting}>&nbsp;</p>
            <p className={styles.commercialLaw}>
              8. Real Estate and Construction Law
            </p>
            <p className={styles.mergersAndAcquisitionsAssi}>
              <span className={styles.mergersAndAcquisitionsAssi1}>
                <span>
                  - Real Estate Transactions: Assisting with the purchase, sale,
                  and leasing of commercial and residential properties.
                </span>
              </span>
            </p>
            <p className={styles.mergersAndAcquisitionsAssi}>
              <span className={styles.mergersAndAcquisitionsAssi1}>
                <span>
                  - Construction Contracts: Drafting and negotiating construction
                  contracts, and advising on construction disputes.
                </span>
              </span>
            </p>
            <p className={styles.complianceAuditsConducting}>&nbsp;</p>
            <p className={styles.commercialLaw}>
              9. Banking and Finance
            </p>
            <p className={styles.mergersAndAcquisitionsAssi}>
              <span className={styles.mergersAndAcquisitionsAssi1}>
                <span>
                  - Financing Transactions: Advising on a variety of financing
                  transactions, including syndicated loans and project finance.
                </span>
              </span>
            </p>
            <p className={styles.mergersAndAcquisitionsAssi}>
              <span className={styles.mergersAndAcquisitionsAssi1}>
                <span>
                  - Regulatory Advice: Providing guidance on banking regulations
                  and compliance with financial services laws.
                </span>
              </span>
            </p>
            <p className={styles.mergersAndAcquisitionsAssi}>
              <span className={styles.mergersAndAcquisitionsAssi1}>
                <span>{`- Debt Restructuring: Assisting with the restructuring of debt and advising on insolvency `}</span>
              </span>
            </p>
            <p className={styles.mergersAndAcquisitionsAssi}>
              <span className={styles.mergersAndAcquisitionsAssi1}>
                <span>matters.</span>
              </span>
            </p>
            <p className={styles.blankLine}>
              <span className={styles.mergersAndAcquisitionsAssi1}>
                <span>&nbsp;</span>
              </span>
            </p>
            <p className={styles.complianceAuditsConducting}>&nbsp;</p>
            <p className={styles.commercialLaw}>
              10. Family and Personal Law
            </p>
            <p className={styles.complianceAuditsConducting}>
              - Family Disputes: Handling family law matters, including divorce,
              child custody, and alimony.
            </p>
            <p className={styles.complianceAuditsConducting}>
              - Wills and Estate Planning: Advising on estate planning, drafting
              wills, and managing probate processes.
            </p>
            <p className={styles.complianceAuditsConducting}>
              - Immigration Law: Assisting with immigration matters, including
              visas and residency permits.
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Services2;
