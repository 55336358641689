import Header from "./Header";
import PropTypes from "prop-types";
import styles from "./PageHaeder.module.css";

const PageHaeder = ({ className = "" ,pageName ,pageDetails =`Triumph & Co. is an international law firm with a global presence & local expertise offering innovative legal and fiscal services, with offices in Lebanon and the UAE, and currently expanding into London-UK, and Saudi Arabia. ` }) => {
  return (
    <section className={[styles.pagehaeder, className].join(" ")}>
      <Header
        headerHeight="unset"
        headerBackgroundColor="#002340"
        headerPadding="0rem"
        logoBackground="white"
        logoPadding="0px 5px 5px 5px"
        logoBorderRadius="10px"
        theme="Dark"
        pageName={pageName}
      />
      <div className={styles.aboutUsParent}>
        <h1 className={styles.aboutUs}>{pageName}</h1>
        <header
          className={styles.triumphCo}
        >{pageDetails}</header>
      </div>
    </section>
  );
};

PageHaeder.propTypes = {
  className: PropTypes.string,
  pageName:PropTypes.string,
  pageDetails: PropTypes.string
};

export default PageHaeder;
