import PageHaeder from "../components/PageHaeder";
import LawerDetaies from "../components/LawerDetaies";
import Footer from "../components/Footer";
import styles from "./LawyerDetailMaryiam.module.css";
import { useLocation } from "react-router-dom";

const LawyerDetailMaryiam = () => {
  const {state}  = useLocation();
  console.log(state)
  return (
    <div className={styles.lawyerDetailMaryiam}>
      <PageHaeder pageName={"Team"} pageDetails="At TRIUMPH & CO., we are proud to serve a diverse and distinguished clientele that includes multinational corporations, financial institutions, government entities, and high-net-worth individuals. Our clients come from various sectors such as finance, real estate, technology, healthcare, and more, reflecting our ability to provide specialized legal services across a wide range of industries." />
      <LawerDetaies postion={state.position} name1={state.name} education={state.education} experience={state.experience}  image={state.image}/>
      <Footer />
    </div>
  );
};

export default LawyerDetailMaryiam;
