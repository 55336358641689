import Services from "./Services";
import PropTypes from "prop-types";
import styles from "./Services1.module.css";

const Services1 = ({ className = "" }) => {
  return (
    <section className={[styles.services, className].join(" ")}>
      <div className={styles.areasContainer}>
        <div className={styles.practiceAreas}>
          <img
            className={styles.maskGroupIcon}
            loading="lazy"
            alt=""
            src={process.env.PUBLIC_URL+"/mask-group-5@2x.png"}
          />
          <h1 className={styles.ourPracticeAreas}>
            Our practice areas include:
          </h1>
        </div>
        <div className={styles.areasWeServeParent}>
          <h1 className={styles.areasWeServe}>Areas We Serve</h1>
          <h2
            className={styles.triumphCo}
          >{`TRIUMPH & CO. is dedicated to providing exceptional legal services across multiple regions. With offices in Lebanon and the UAE, and our ongoing expansion into London-UK, and Saudi Arabia, we are well-positioned to serve clients throughout the Middle East, Europe, and the wider global market through a network of partners firms`}</h2>
        </div>
      </div>
      <div className={styles.serviceItems}>
        <Services serviceName="Arbitration" arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3.svg"} />
        <Services
          serviceName="Banking and Finance"
          servicesAlignSelf="stretch"
          servicesBorderBottom="2px solid #fffcfc"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3.svg"}
        />
      </div>
      <div className={styles.serviceItems1}>
        <Services
          serviceName="Litigation"
          servicesAlignSelf="unset"
          servicesBorderBottom="2px solid #fffcfc"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3-2.svg"}
        />
        <Services
          serviceName="Data Privacy"
          servicesAlignSelf="unset"
          servicesBorderBottom="2px solid #fffcfc"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3-2.svg"}
        />
      </div>
      <div className={styles.serviceItems1}>
        <Services
          serviceName="Employment"
          servicesAlignSelf="unset"
          servicesBorderBottom="2px solid #fffcfc"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3-4.svg"}
        />
        <Services
          serviceName="Intellectual Property"
          servicesAlignSelf="unset"
          servicesBorderBottom="2px solid #fffcfc"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3-4.svg"}
        />
      </div>
      <div className={styles.serviceItems1}>
        <Services
          serviceName="Real Estate"
          servicesAlignSelf="unset"
          servicesBorderBottom="2px solid #fffcfc"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3-6.svg"}
        />
        <Services
          serviceName="Competition and Antitrust"
          servicesAlignSelf="unset"
          servicesBorderBottom="2px solid #fffcfc"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3-6.svg"}
        />
      </div>
      <div className={styles.serviceItems1}>
        <Services
          serviceName="Construction"
          servicesAlignSelf="unset"
          servicesBorderBottom="2px solid #fffcfc"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3-8.svg"}
        />
        <Services
          serviceName="Criminal"
          servicesAlignSelf="unset"
          servicesBorderBottom="2px solid #fffcfc"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3-8.svg"}
        />
      </div>
      <div className={styles.serviceItems1}>
        <Services
          serviceName="Corporate"
          servicesAlignSelf="unset"
          servicesBorderBottom="2px solid #fffcfc"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3-10.svg"}
        />
        <Services
          serviceName="Taxation"
          servicesAlignSelf="unset"
          servicesBorderBottom="2px solid #fffcfc"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3-10.svg"}
        />
      </div>
      <div className={styles.serviceItems1}>
        <Services
          serviceName="Commercial"
          servicesAlignSelf="unset"
          servicesBorderBottom="2px solid #fffcfc"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3-12.svg"}
        />
        <Services
          serviceName="Business Law"
          servicesAlignSelf="unset"
          servicesBorderBottom="2px solid #fffcfc"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3-12.svg"}
        />
      </div>
      <div className={styles.serviceItems1}>
        <Services
          serviceName="Blockchain and crypto"
          servicesAlignSelf="unset"
          servicesBorderBottom="unset"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3-14.svg"}
        />
        <Services
          serviceName="Wealth Planning"
          servicesAlignSelf="unset"
          servicesBorderBottom="unset"
          arrowRight3={process.env.PUBLIC_URL+"/arrow--right-3-14.svg"}
        />
      </div>
    </section>
  );
};

Services1.propTypes = {
  className: PropTypes.string,
};

export default Services1;
