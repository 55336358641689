import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Homepage from "./pages/Homepage";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Lawyer from "./pages/Lawyer";
import LawyerDetailMaryiam from "./pages/LawyerDetailMaryiam";
import WhyChoose from "./pages/WhyChoose";
import Services2 from "./pages/Services2";


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/about-us":
        title = "";
        metaDescription = "";
        break;
      case "/contact-us":
        title = "";
        metaDescription = "";
        break;
      case "/lawyer":
        title = "";
        metaDescription = "";
        break;
      case "/lawyer-detail":
        title = "";
        metaDescription = "";
        break;
      case "/why-choose":
        title = "";
        metaDescription = "";
        break;
      case "/services":
        title = "";
        metaDescription = "";
        break;

    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]',
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/lawyer" element={<Lawyer />} />
      <Route path="/lawyer-detail" element={<LawyerDetailMaryiam />} />
      <Route path="/why-choose" element={<WhyChoose />} />
      <Route path="/services" element={<Services2 />} />
    </Routes>
  );
}
export default App;
