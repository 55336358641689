import { Button } from "@mui/material";
import PropTypes from "prop-types";
import styles from "./Button1.module.css";

const Button1 = ({
  className = "",
  state = "Default",
  name1 = "Get Statrted",
  button1Button,

}) => {
  return (
    <Button
      className={[styles.button, className].join(" ")}
      disableElevation
      variant="contained"
      sx={{
        textTransform: "none",
        color: "#fff",
        fontSize: "24",
        background: "#002340",
        borderRadius: "0px 0px 0px 0px",
        "&:hover": { background: "#002340" },
        width: 250,
      }}
      data-state={state}
    >
      {button1Button}

    </Button>
  );
};

Button1.propTypes = {
  className: PropTypes.string,
  name1: PropTypes.string,
  button1Button: PropTypes.string,


  /** Variant props */
  state: PropTypes.string,
};

export default Button1;
