import Header from "../components/Header";
import Hero from "../components/Hero";
import News from "../components/News";
import Support from "../components/Support";
import Services1 from "../components/Services1";
import Attorney from "../components/Attorney";
import LastCases from "../components/LastCases";
import Testimonials from "../components/Testimonials";
import WhyChoseUs from "../components/WhyChoseUs";
import Footer from "../components/Footer";
import styles from "./Homepage.module.css";

const Homepage = () => {
  return (
    <div className={styles.homepage}>
      <Header />
      <Hero />
      <News />
      <Support />
      <section className={styles.globalpresence}>
        <h1 className={styles.globalPresence}>Global Presence</h1>
        <img
          className={styles.image106Icon}
          loading="lazy"
          alt=""
          src={process.env.PUBLIC_URL+"/image-106@2x.png"}
        />
      </section>
      <Services1 />
      <Attorney />
      <LastCases />
      <Testimonials />
      <WhyChoseUs />
      <Footer />
    </div>
  );
};

export default Homepage;
