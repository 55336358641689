import FrameComponent1 from "./FrameComponent1";
import PropTypes from "prop-types";
import styles from "./LawerDetaies.module.css";

const LawerDetaies = ({
  className = "",
  postion = "Partner",
  name1 = "Mariam Al Shamsi",
  experience = `
                Mariam is licensed as a lawyer across the UAE.
                Mariam is a highly skilled and experienced lawyer specializing in
                civil, commercial, and criminal cases. Mariam's expertise extends
                to various areas of law, including civil disputes, commercial
                transactions, and criminal defense.
                In addition to her practical experience, Mariam holds a master's
                degree in criminal sciences, which further enhances her
                understanding of the intricacies of criminal law. She is also
                currently pursuing a Ph.D., focusing on advancing her knowledge
                and expertise in her field. With her extensive experience,
                academic achievements, and unwavering dedication, Mariam is a
                highly respected and sought-after lawyer in her field.
              `,
  education = [],
  image = ''
}) => {
  return (
    <section className={[styles.lawerdetaies, className].join(" ")}>
      <FrameComponent1  educationDetails={education} image={image}/>
      <div className={styles.experienceDetailsParent}>
        <div className={styles.experienceDetails}>
          <h1 className={styles.mariamAlShamsi}>{name1}</h1>
          <h1 className={styles.partner}>{postion}</h1>
        </div>
        <div className={styles.experienceDetails1}>
          <h1 className={styles.experience}>EXPERIENCE</h1>
          <h2 className={styles.mariamIsLicensedContainer}>
            {experience.split('\n').map(text =>  <p className={styles.mariamIsLicensed}>
              {text}
            </p>)}
          </h2> 
        </div>
      </div>
    </section>
  );
};

LawerDetaies.propTypes = {
  className: PropTypes.string,
  postion: PropTypes.string,
  name1: PropTypes.string,
  experience:PropTypes.string,
  eduction: PropTypes.array
};

export default LawerDetaies;
