import { useCallback } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./WhyChoseUs.module.css";

const WhyChoseUs = ({ className = "" }) => {
  const navigate = useNavigate();

  const onSeeDetailButtonContainerClick = useCallback(() => {
    navigate("/why-choose");
  }, [navigate]);

  return (
    <section className={[styles.whychoseus, className].join(" ")}>
      <div className={styles.whyChooseUsContainer}>
        <div className={styles.whyChooseUsContent}>
          <div className={styles.whyChooseUsTitle}>
            <h1 className={styles.whyChooseTriumphContainer}>
              <p className={styles.whyChoose}>WHY CHOOSE</p>
              <p className={styles.whyChoose}>{`TRIUMPH & CO`}</p>
            </h1>
          </div>
          <h1 className={styles.chooseTriumphContainer}>
            <p
              className={styles.whyChoose}
            >{`Choose TRIUMPH & CO. for a partnership that combines international experience, `}</p>
            <p className={styles.whyChoose}>
              local insight, and a steadfast commitment to your success. We are
              your trusted legal advisors, dedicated to helping you navigate the
              complexities of the legal world with confidence.
            </p>
          </h1>
        </div>
        <img
          className={styles.whyChooseUsContainerChild}
          alt=""
          src={process.env.PUBLIC_URL+"/vector-2-1.svg"}
        />
        <div className={styles.imageContainer}>
          <img
            className={styles.image120Icon}
            loading="lazy"
            alt=""
            src={process.env.PUBLIC_URL+"/image-120@2x.png"}
          />
        </div>
      </div>
      <Button
        className={styles.seeDetailButtonContainer}
        disableElevation
        variant="contained"
        sx={{
          textTransform: "none",
          color: "#fff",
          fontSize: "24",
          background: "#002340",
          borderRadius: "0px 0px 0px 0px",
          "&:hover": { background: "#002340" },
          width: 250,
        }}
        onClick={onSeeDetailButtonContainerClick}
      >
        See detail
      </Button>
    </section>
  );
};

WhyChoseUs.propTypes = {
  className: PropTypes.string,
};

export default WhyChoseUs;
