const Attorneys = [
    {
        name:"Mariam Al Shamsi",
        postion:"partner",
        education:[
            {degree:"Bachelor of Law",university:"American University in the Emirates"},
            {degree:"Master in Criminal Sciences",university:"American University in the Emirates"},
            {degree:"PHD Candidate",university:"American University in the Emirates"}
        ],
        maskGroup : process.env.PUBLIC_URL+"/mask-group1@2x.png",
        experience:`Mariam is licensed as a lawyer across the UAE.
Mariam is a highly skilled and experienced lawyer specializing in civil, commercial, and criminal cases. Mariam's expertise extends to various areas of law, including civil disputes, commercial transactions, and criminal defense.
In addition to her practical experience, Mariam holds a master's degree in criminal sciences, which further enhances her understanding of the intricacies of criminal law. She is also currently pursuing a Ph.D., focusing on advancing her knowledge and expertise in her field. With her extensive experience, academic achievements, and unwavering dedication, Mariam is a highly respected and sought-after lawyer in her field.`
    },
    {
        name:"Cesar Ghaleb",
        postion :"Founding Partner",
        maskGroup: process.env.PUBLIC_URL+"/mask-group-11@2x.png",
        education:[
            {degree:"LLM  (Corporate and Commercial Law with Distinction),",university:"Queen Mary University of London"},
            {degree:"DEA (Private Law)",university:"Saint Joseph University"},
            {degree:"Aptitude Certificate in Mediation",university:"Saint Joseph University"},
            {degree:"LLB" ,university:"Saint Joseph University"}
        ],
        experience:`Cesar is a sector-agnostic lawyer admitted to the Beirut Bar Association and is also a qualified Solicitor of the Senior Courts of England and Wales. Cesar is also a registered Part 2 practitioner before the DIFC Courts, and regularly attends hearing before said courts representing clients.
  Cesar leads the Firm’s corporate department, handling a wide range of corporate transactions in various sectors such as pharma, construction, and retail, as well as handling all sorts of commercial agreements ranging from franchising to licensing to trade, among others.
  He is also is also involved in international tax and has advised numerous clients on tax planning, VAT, and group restructuring.  Always looking to connect any area of law to corporate transactions, Cesar is an expert on the effects of taxes on M&A decisions and their role in target valuation.`
            
    },
    {
        name:"Issam Bader",
        postion:"partner",
        maskGroup: process.env.PUBLIC_URL+"/mask-group-2@2x.png",
        education:[
            {degree:"LLB, Damascus University" ,university:""}
        ],
        experience:`Issam has acquired over twenty years of litigation and disputes experience in the UAE, representing clients in cases before all courts in the UAE including civil, commercial, and criminal.
Issam is a key member in the Firm’s litigation practice, and leads on the major and complex disputes, representing clients in the construction sector in disputes valued at billions of dirhams in the UAE. 
Issam also regularly represents clients in Ad hoc arbitrations in the UAE.`
    },
    { 
        name:"Georges Abou Zogheib",
        postion:"Partner",
        maskGroup: process.env.PUBLIC_URL+"/mask-group-3@2x.png",
        education:[
    {degree:"DESS in Arbitration and Alternative Modes of Conflict Resolution" ,university:"Lebanese University"},
    {degree:"LLB", university:"Holy Spirit University of Kaslik"}
  ],
        experience:`Georges is a seasoned lawyer with over 15 years of experience in disputes and litigation work. He is heavily involved in all aspects of real estate and construction matters and their operational impact, as well as providing legal advice and consulting on various legal matters.
Georges is a core member of the Firm’s real estate and construction desk, both at a transactional and dispute resolution level. He handles a variety of contentious real estate and construction issues, valued at billions of dirhams, including development and investment. On the contentious side, he is involved in the Firm’s highest profile construction and litigation cases in the UAE.`
    },
    { 
        name: "Paul Kelly",
        postion: "Partner",
        maskGroup: process.env.PUBLIC_URL+"/mask-group-41@2x.png",
        education:[
            {degree:"BSc (Hons) in Quantity Surveying" ,university:"Nottingham Trent University"},
            {degree:"Advanced Professional Award in ExpertWitness Evidence ", university:"London UK (LETAPAEWE)"},
            {degree:"Undergraduate at the RGU Aberdeen UK",university:""},
            {degree:"Studying Construction Law and Arbitration - LLM/MSc",university:""}
          ],
        experience:`Paul is a Member of the Royal Institute of Chartered Surveyors (MRICS), Member of the Chartered Institute of Arbitrators (MCIArb), Member of the Chartered Institute of Building (MCIOB), and Arbitration Review MENA Group Debate Series.
        Paul has over 15 years of experience in the construction industry especially in the MEA. He has extensive experience in construction disputes, as well as in the cost and commercial management of construction projects. Paul has advised a range of international clients on quantum claims, contract & claims documentation, and dispute resolution & avoidance. He has represented clients in high profile arbitrations governed by the ICC Rules, DIAC, ADCCAC, DIFC-LCIA, among others.`
    },    
    { 
        name:"Nour Abi Rached",
        postion:"Associate",
        maskGroup: process.env.PUBLIC_URL+"/mask-group-51@2x.png",
        education:[
            {degree:"LLM in International Business Law" ,university:"King’s College University- London"},
            {degree:"Master’s in International Private Law and International Commerce (with distinction)", university:"Paris II Panthéon Assas- Paris"},
            {degree:"LLB",university:"Saint Joseph University- Beirut"},
        ],
        experience:`Nour is a highly qualified trilingual lawyer (English, French, and Arabic), who holds an LLM from King’s College-London, a leading global university, and another Master’s from Assas university in Paris, also globally leading university. Nour is currently preparing to sit the next Solicitors Qualifying Examination to qualify as a Solicitor in England and Wales.
        Nour has specialized in both of her Master’s degrees in arbitration and dispute resolution. Nour is a member of the arbitration practice, and has worked extensively on arbitrations before DIAC, ICC, QICCA, among others, with a focus on construction disputes.`
    },
    { 
        name:"Ahmad Rima",
        postion:"Associate",
        maskGroup: process.env.PUBLIC_URL+"/mask-group-61@2x.png",
        education:[
            {degree:"LLB from the Lebanese University",university:"Master's of Laws. Lebanese University."}
          ],
        experience:`Ahmad Rima is a valued associate at Triumph & Co., specializing in  employment law across Lebanon, the UAE, and KSA. With a keen focus  on commercial matters, Ahmad brings extensive expertise in franchise  agreements, real estate, and trade sectors. His regular advisory role on  these issues underscores his commitment to delivering insightful and  strategic legal solutions.
        His educational background, combined with his practical experience,  positions him as a reliable resource for clients navigating complex legal  frameworks.`
    },
    
]

export default Attorneys