import { Button } from "@mui/material";
import FirstNameField from "./FirstNameField";
import PropTypes from "prop-types";
import styles from "./FormContainer.module.css";

const FormContainer = ({ className = "" }) => {
  return (
    <div className={[styles.formContainer, className].join(" ")}>
      <div className={styles.formFields}>
        <h1 className={styles.getInTouch}>Get In touch</h1>
        <div className={styles.inputFields}>
          <div className={styles.nameFields}>
            <FirstNameField
              firstName="First Name*"
              enterFirstName="Enter first name"
            />
            <FirstNameField
              firstName="Last Name*"
              enterFirstName="Enter last email"
            />
          </div>
          <div className={styles.nameFields}>
            <FirstNameField
              firstName="Your Phone*"
              enterFirstName="Enter your phone"
            />
            <FirstNameField
              firstName="Your Email*"
              enterFirstName="Enter your email"
            />
          </div>
          <div className={styles.messageField}>
            <div className={styles.messageInputContainer}>
              <div className={styles.messageOptional}>
                <span>{`Message `}</span>
                <span className={styles.optional}>(optional)</span>
              </div>
              <textarea
                className={styles.messageInputContainerChild}
                placeholder="Enter message"
                rows={13}
                cols={34}
              />
            </div>
          </div>
        </div>
        <Button
          className={styles.button}
          disableElevation
          variant="contained"
          sx={{
            textTransform: "none",
            color: "#fff",
            fontSize: "24",
            background: "#002340",
            borderRadius: "0px 0px 0px 0px",
            "&:hover": { background: "#002340" },
            width: 250,
          }}
        >
          Send
        </Button>
      </div>
      <div className={styles.contactImageParent}>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4249.843760916855!2d55.25796391156655!3d25.186803077624784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69b4df276c75%3A0x9dc2da4dd1a0a797!2sThe%20Regal%20Tower!5e1!3m2!1sen!2seg!4v1733189791591!5m2!1sen!2seg"  
      allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{width:"100%",height:"700px"}}></iframe>
      </div>
    </div>
  );
};

FormContainer.propTypes = {
  className: PropTypes.string,
};

export default FormContainer;
